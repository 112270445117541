import * as Dialog from '@radix-ui/react-dialog';
import { ReactNode, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MdClose } from 'react-icons/md';
import { Giveaway, GiveawayTask } from 'views/types/tasks';
import { GiveawayFormScheme } from '../../scheme/taksForm';
import { GiveawayForm } from './Form';
import { MessForm } from './MessageForm';
import { giveawayService } from 'views/services/giveawayService';
import { tasksService } from 'views/services/tasksService';
import { TaskDialog } from 'views/admin/marketplace/components/TaskDialog';

interface GiveawayDialogProps {
  giveaway?: Giveaway;
  children: ReactNode;
  formType?: 'giveaway' | 'message';
}

export const GiveawayDialog = ({
  giveaway,
  children,
  formType,
}: GiveawayDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChangedImage, setIsChangedImage] = useState(false);
  const [isOpenTaskModal, setIsOpenTaskModal] = useState(false);
  const [task, setTask] = useState<GiveawayTask>();
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const isEdit = !!giveaway;

  const form = useForm<GiveawayFormScheme>({
    values: {
      title: isEdit ? giveaway?.title : '',
      description: isEdit ? giveaway?.description : '',
      isActive: isEdit ? giveaway?.isActive : false,
      imageUrl: isEdit ? giveaway?.imageUrl : '',
      isTgPremiumOnly: isEdit ? giveaway?.isTgPremiumOnly : false,
      endDate: isEdit
        ? new Date(giveaway?.endDate).toISOString().split('T')[0]
        : '',
      approximateTime: isEdit ? giveaway.approximateTime : '0',
      rewardAmount: isEdit ? giveaway.rewardAmount : '0',
      withNotification: isEdit ? giveaway.withNotification : false,
    },
  });

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    setIsChangedImage(false);

    if (!isOpen) {
      form.reset();
      setTask(undefined);
    }
  };

  const handleCloseModal = (isOpen: boolean) => {
    setIsOpenTaskModal(isOpen);
    if (!isOpen) {
      setTask(undefined);
    }
  };

  const handleSubmit = async (data: GiveawayFormScheme) => {
    try {
      if (formType === 'message') {
        await tasksService.createMessage(data.task?.message);
      } else if (formType === 'giveaway') {
        if (isEdit) {
          await giveawayService.updateGiveaway(data, giveaway.id);
        } else {
          await giveawayService.createGiveaway(data);
        }
      }
      form.reset();
      handleOpenChange(false);
      setTask(undefined);
    } catch (error) {
      console.warn('Error saving task', error);
    }
  };

  return (
    <>
      <Dialog.Root open={isOpen} onOpenChange={handleOpenChange} modal>
        <Dialog.Trigger asChild>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 z-50 bg-gray-800 bg-opacity-50" />
          <Dialog.Content
            onInteractOutside={e => {
              if ((e.target as HTMLElement).closest('.chakra-toast')) {
                e.preventDefault();
              }
            }}
            className="data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[60] max-h-[85vh] w-[90vw] max-w-[800px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none dark:bg-gray-800 dark:text-white"
          >
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Dialog.Title className="text-mauve12 mb-10 text-[17px] font-medium">
                {isEdit
                  ? 'Edit Giveaway'
                  : formType === 'giveaway'
                    ? 'Create Giveaway'
                    : 'Create Message'}
              </Dialog.Title>
              <div className="max-h-[55dvh] grow overflow-y-auto overflow-x-hidden px-px pr-4">
                <FormProvider {...form}>
                  {formType === 'message' ? (
                    <MessForm />
                  ) : (
                    <GiveawayForm
                      isEdit={isEdit}
                      giveaway={isEdit && giveaway}
                      setIsChangedImage={setIsChangedImage}
                      setIsOpenTaskModal={setIsOpenTaskModal}
                      setTask={setTask}
                    />
                  )}
                </FormProvider>
              </div>
              <div className="mt-[10px] flex flex-col">
                {form.formState.errors.root && (
                  <span className="text-sm capitalize text-red-500 dark:text-red-400">
                    {form.formState.errors.root.message}
                  </span>
                )}
                <button
                  type="submit"
                  className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:pointer-events-none disabled:opacity-50 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  disabled={
                    form.formState.isSubmitting ||
                    (!form.formState.isDirty && !isChangedImage) ||
                    isLoadingImage
                  }
                >
                  {form.formState.isSubmitting ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </form>
            <TaskDialog
              formType="task"
              onOpenChange={handleCloseModal}
              giveawayId={!!giveaway && giveaway.id}
              hasQuiz={false}
              hasStory={false}
              isFormOpen={isOpenTaskModal}
              task={task}
              isLoadingImage={isLoadingImage}
              setIsLoadingImage={setIsLoadingImage}
            >
              <div />
            </TaskDialog>
            <Dialog.Close asChild>
              <button
                type="button"
                className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute right-[10px] top-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
                aria-label="Close"
              >
                <MdClose />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
